@import '~@/assets/scss/base';

.simpleCalcCon {
  width: $simple-width;
  height: $simple-height;
  padding: $container-pad;
  background-color: $bg-light-color;
  border-radius: $bd-radius;
}

.angInputCon {
  width: $simple-width;
  position: relative;
  height: $ang-height;
  line-height: $ang-height;
  margin: 3px 0;
}

.angInput {
  box-sizing: border-box;
  width: $simple-width;
  height: $ang-height;
  padding: 5px 10px;
  outline: none;
  border: none;
  box-shadow: 0 0 4px -2px $shadow-color;
  border-radius: $bd-radius;
  font-size: 1.2em;

  &::placeholder {
    color: #ccc;
  }

  &:hover {
    border: none;
  }
}

.radioBox {
  $radio-size: 38px;
  width: $simple-width;
  height: $radio-size;
  margin: 10px 0;
  padding: 8px 0;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, .6);
  border-radius: $bd-radius;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .radioInput {
    display: none;
  }

  .radioInput+label {
    box-sizing: border-box;
    display: inline-block;
    width: $radio-size;
    height: $radio-size;
    text-align: center;
    font-size: 1.3em;
    line-height: $radio-size;
    transition: background-color .3s linear, border-radius .3s ease;
    border-radius: 3px;
    border: 1px double transparent;

    &:hover {
      background-color: #ccc;
      border: 1px double #ccc;
    }
  }

  .radioInput:checked+label {
    border-radius: 50%;
    background: #ccc;
  }
}
@import "~@/assets/scss/base";

.advancedCon {
  // box-sizing: border-box;
  width: $advanced-width;
  height: $advanced-height;
  padding: $container-pad;
  background-color: $bg-light-color;
  border-radius: $bd-radius;
  margin: 8px 0;
  position: relative;
  .inputTa {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    resize: none;
    font-size: 1.4em;
    box-sizing: border-box;
    outline: none;
    border: none;
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.4);
    border-radius: $bd-radius;
    font-size: 1.2em;
    &::placeholder {
      color: #ccc;
    }
    &:hover {
      border: none;
    }
  }
  .btnCon{
    width: 100%;
    height: 36px;

    .runBtn{
      width: 78%;
      height: 24px;
      margin: 6px 0;
      cursor: pointer;
      font-size: 13px;
      box-shadow: 0 0 5px -2px $shadow-color;
      border: none;
      border-radius: $bd-radius;
      transition: box-shadow .1s linear;
      &:active{
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0);
      }
    }
    .clsBtn{
      width: 20%;
      height: 24px;
      margin: 6px 0px 6px 5px;
      cursor: pointer;
      font-size: 13px;
      box-shadow: 0 0 5px -2px $shadow-color;
      border: none;
      border-radius: $bd-radius;
      transition: box-shadow .1s linear;
      &:active{
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0);
      }

    }
  }
  .calcProcessPanel{
    font-size: 13px;
    position: absolute;
    top: 160px;
    left: $container-pad;
    bottom: $container-pad;
    right: $container-pad;
    overflow: auto;
  }
}

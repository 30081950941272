@import '~@/assets/scss/base';


.App{
  width: 100%;
  height: 100%;
  min-height: 390px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
@media screen and (max-width:700px) {
  div.App{
    flex-direction: column;
    min-height: 768px;
    // flex-wrap: nowrap;
  }
}
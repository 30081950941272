@font-face {
  font-family: "iconfont"; /* Project id 2785450 */
  src: url('iconfont.woff2?t=1630567328562') format('woff2'),
       url('iconfont.woff?t=1630567328562') format('woff'),
       url('iconfont.ttf?t=1630567328562') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--hao:before {
  content: "\e600";
}

.icon-jia:before {
  content: "\e604";
}

.icon-chuhao:before {
  content: "\e771";
}

.icon-chenghao:before {
  content: "\e601";
}


$bg-dark-color:#333;
$bg-light-color:#eee;

$shadow-color:rgba(0, 0, 0, 0.6);
$ang-height:34px;
$simple-width:280px;
$advanced-width:280px;

$simple-height:350px;
$advanced-height:350px;

$container-pad:14px;

$bd-radius:4px;